import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

export const identificadorColumns: CustomGridColumns[] = [
  {
    field: 'num_item',
    headerName: 'Seq',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'cfop',
    headerName: 'CFOP',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'flg_bonificado',
    headerName: 'Bonificado',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
    renderCell(row) {
      return <span>{row.flg_bonificado ? 'Sim' : 'Não'}</span>;
    },
  },
  {
    field: 'num_ncm',
    headerName: 'NCM',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'des_referencia',
    headerName: 'Referência',
    disableColumnMenu: true,
    width: 150,
    sortable: false,
  },
  {
    field: 'cod_gtin_principal',
    headerName: 'EAN',
    disableColumnMenu: true,
    width: 150,
    sortable: false,
  },
  {
    field: 'cod_produto',
    headerName: 'Código do Produto',
    disableColumnMenu: true,
    width: 130,
    sortable: false,
  },
  {
    field: 'des_produto',
    headerName: 'Descrição',
    disableColumnMenu: true,
    width: 350,
    sortable: false,
  },
];
