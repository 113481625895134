import React, { useEffect } from 'react';

import Modal, { ModalBody, ModalHeader } from '@atlaskit/modal-dialog';
import { Col, Form, Row } from 'react-bootstrap';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import { useForm } from 'react-hook-form';

import { InputText, InputMoney } from '~/components/NovosInputs';
import { schema } from './validations';
import { yupResolver } from '@hookform/resolvers/yup';
import ToggleDefault from '~/components/ToggleDefault';
import Separator from '~/components/Separator';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { somaCampos } from '~/pages/EntradaNFE/functions/Procedures';

const ModalIgnoraBC: React.FC = () => {
  const {
    produtoModalNaoCalcBc,
    setProdutoModalNaoCalcBc,
    setShowModalNaoCalcBc,
    produtos,
    setProdutos,
    fornecedor,
    loja,
  } = useEntradaNFE();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    serializeValuesToForm();
  }, []);

  const serializeValuesToForm = () => {
    setValue('num_item', produtoModalNaoCalcBc.num_item);
    setValue('des_produto', produtoModalNaoCalcBc.des_produto);
    setValue(
      'val_tot_bc_icms',
      formatCurrencyAsText(produtoModalNaoCalcBc.val_tot_bc_icms),
    );
    setValue(
      'val_tot_icms_op',
      formatCurrencyAsText(produtoModalNaoCalcBc.val_tot_icms_op),
    );

    setValue(
      'val_tot_isento',
      formatCurrencyAsText(produtoModalNaoCalcBc.val_tot_isento),
    );
    setValue(
      'val_tot_outros_icms',
      formatCurrencyAsText(produtoModalNaoCalcBc.val_tot_outros_icms),
    );
    setProdutoModalNaoCalcBc({
      ...produtoModalNaoCalcBc,
      flg_nao_calc_bc: produtoModalNaoCalcBc.flg_nao_calc_bc,
    });
  };

  const onUpdateProdutoClick = handleSubmit(async (data) => {
    const newProdutos = produtos.map((produto) => {
      if (produto.num_item === produtoModalNaoCalcBc.num_item) {
        data.val_tot_bc_icms = transformAsCurrency(data.val_tot_bc_icms);
        data.val_tot_icms_op = transformAsCurrency(data.val_tot_icms_op);
        data.val_tot_isento = transformAsCurrency(data.val_tot_isento);
        data.val_tot_outros_icms = transformAsCurrency(
          data.val_tot_outros_icms,
        );
        data.flg_nao_calc_bc = produtoModalNaoCalcBc.flg_nao_calc_bc;
        produto = { ...produtoModalNaoCalcBc, ...data };
      }
      return produto;
    });

    setProdutos(newProdutos);
    setShowModalNaoCalcBc(false);
  });

  const osSwitchFlgNaoCalcBc = () => {
    if (produtoModalNaoCalcBc.flg_nao_calc_bc) {
      somaCampos(fornecedor, loja, produtoModalNaoCalcBc);
      serializeValuesToForm();
    }

    setProdutoModalNaoCalcBc({
      ...produtoModalNaoCalcBc,
      flg_nao_calc_bc: !produtoModalNaoCalcBc.flg_nao_calc_bc,
    });
  };

  return (
    <Modal onClose={() => setShowModalNaoCalcBc(false)}>
      <ModalHeader>
        <h4>Item da Nota Fiscal</h4>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col sm={2} md={2}>
              <InputText
                maxLength={50}
                label="Item"
                placeholder=""
                control={control}
                name="num_item"
                register={register}
                isError={false}
                disabled
              />
            </Col>
            <Col sm={10} md={10}>
              <InputText
                maxLength={50}
                label="Descrição"
                placeholder=""
                control={control}
                name="des_produto"
                register={register}
                isError={false}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2} md={2}>
              <ToggleDefault
                labelText="Ignora BC?"
                setChecked={produtoModalNaoCalcBc.flg_nao_calc_bc}
                onSwitch={() => osSwitchFlgNaoCalcBc()}
              />
            </Col>
            <Col sm={10} md={10}>
              <Separator labelText="" />
            </Col>
          </Row>
          <Row>
            <Col sm={3} md={3}>
              <InputMoney
                label="BC ICMS"
                placeholder=""
                min={0}
                showIcon={false}
                name="val_tot_bc_icms"
                register={register}
                isError={false}
                disabled={!produtoModalNaoCalcBc.flg_nao_calc_bc}
              />
            </Col>
            <Col sm={3} md={3}>
              <InputMoney
                label="ICMS OP"
                placeholder=""
                min={0}
                showIcon={false}
                name="val_tot_icms_op"
                register={register}
                isError={false}
                disabled={!produtoModalNaoCalcBc.flg_nao_calc_bc}
              />
            </Col>
            <Col sm={3} md={3}>
              <InputMoney
                maxLength={50}
                label="Isento"
                placeholder=""
                min={0}
                showIcon={false}
                name="val_tot_isento"
                register={register}
                isError={false}
                disabled={!produtoModalNaoCalcBc.flg_nao_calc_bc}
              />
            </Col>
            <Col sm={3} md={3}>
              <InputMoney
                maxLength={50}
                label="Outros"
                placeholder=""
                min={0}
                showIcon={false}
                name="val_tot_outros_icms"
                register={register}
                isError={false}
                disabled={!produtoModalNaoCalcBc.flg_nao_calc_bc}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-end justify-content-end mt-4 mb-4">
            <Col sm={4}>
              <CancelButton
                onClick={() => {
                  setShowModalNaoCalcBc(false);
                }}
              >
                Cancelar
              </CancelButton>
            </Col>
            <Col sm={4}>
              <ConfirmButton onClick={() => onUpdateProdutoClick()}>
                Alterar
              </ConfirmButton>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalIgnoraBC;
