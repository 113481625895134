import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { formatCurrencyAsText } from '~/utils/functions';

export const totaisColumns: CustomGridColumns[] = [
  {
    field: 'val_tabela_final',
    headerName: 'Tot. Final',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tabela_final } = row;
      return <span>{formatCurrencyAsText(val_tabela_final)}</span>;
    },
  },
  {
    field: 'val_tabela_final_emb',
    headerName: 'Tot. Final Emb',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tabela_final_emb } = row;
      return <span>{formatCurrencyAsText(val_tabela_final_emb, 2, 2)}</span>;
    },
  },
  {
    field: 'val_custo_cheio',
    headerName: 'Valor Custo Fiscal',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_custo_cheio } = row;
      return <span>{formatCurrencyAsText(val_custo_cheio, 2, 2)}</span>;
    },
  },
  {
    field: 'val_custo_rep',
    headerName: 'Valor Custo Rep. NF',
    width: 170,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_custo_rep } = row;
      return <span>{formatCurrencyAsText(val_custo_rep, 2, 2)}</span>;
    },
  },
  {
    field: 'val_custo_rep_loja',
    headerName: 'Valor Custo Rep. Lj',
    width: 170,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_custo_rep_loja } = row;
      return <span>{formatCurrencyAsText(val_custo_rep_loja, 2, 2)}</span>;
    },
  },
];
