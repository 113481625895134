import React, { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import { CustomSeparator } from './CustomSeparator';
import { FormItens } from './FormItens';
import ModalIgnoraBC from './ModalIgnoraBC';
import { TableItens } from './TableItens';

export const Itens: React.FC = () => {
  const { showModalNaoCalcBc, xmlImportado, showFormItens, setShowFormItens } =
    useEntradaNFE();

  useEffect(() => {
    if (xmlImportado) {
      setShowFormItens('1');
    }
  }, [setShowFormItens, xmlImportado]);

  return (
    <>
      {showModalNaoCalcBc && <ModalIgnoraBC />}
      <Accordion defaultActiveKey="0">
        <div className="row accordion-row">
          <div
            className="col-12"
            style={{ paddingRight: '0px' }}
            title={
              !xmlImportado
                ? 'Clique para expandir/reduzir'
                : 'Lançamento de produtos indisponível quando há XML importado.'
            }
          >
            <CustomSeparator
              label="Itens"
              eventKey={showFormItens}
              onChange={(newEventKey) => {
                if (!xmlImportado) setShowFormItens(newEventKey);
              }}
            />
          </div>
        </div>
        <div id="accordionParams" className="accordion">
          <Accordion.Collapse
            as={Button}
            eventKey={showFormItens}
            className="params-data"
          >
            <FormItens />
          </Accordion.Collapse>
        </div>
      </Accordion>
      <TableItens />
    </>
  );
};
