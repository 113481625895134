import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { formatCurrencyAsText } from '~/utils/functions';

const getDescricaoTribucao = (tipo: number) => {
  switch (tipo) {
    case 0:
      return 'Tributado';
    case 1:
      return 'Isento';
    case 2:
      return 'Redução';
    case 3:
      return 'Substituição';
    case 4:
      return 'Suspensão';
    case 5:
      return 'Diferido';
    case 6:
      return 'Outros ICMS';
    case 7:
      return 'Portaria CAT-38';
    default:
      return 'Não Tributado';
  }
};

export const tributacaoCadastroColumns: CustomGridColumns[] = [
  {
    field: 'tipo_tributacao',
    headerName: 'Tributação Entrada',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { tipo_trib_entrada } = row;
      return <span>{getDescricaoTribucao(tipo_trib_entrada)}</span>;
    },
  },
  {
    field: 'val_icms_entrada',
    headerName: 'Aliq. ICMS Ent',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_icms_entrada } = row;
      return <span>{formatCurrencyAsText(val_icms_entrada)}</span>;
    },
  },
  {
    field: 'val_reducao_entrada',
    headerName: 'Red. BC Entrada',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_reducao_entrada } = row;
      return <span>{formatCurrencyAsText(val_reducao_entrada)}</span>;
    },
  },
];
