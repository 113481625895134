import React from 'react';
import { Button, ButtonProps, Row } from 'react-bootstrap';

// import { GridColumns, GridColDef } from '@material-ui/data-grid';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

// import { tableItensColumns } from '~/pages/EntradaNFE/protocols';
import { tableItensColumns } from './TableItens';

import { ScrollButtonsContainer } from './styles';

interface ScrollButtonProps extends ButtonProps {
  field: string;
}

const ScrollButton = ({ onClick, field, ...rest }: ScrollButtonProps) => {
  return <Button {...rest} onClick={() => scroll(field)} />;
};

const scroll = (field: string) => {
  const index = tableItensColumns.findIndex(
    (column: CustomGridColumns) => column.field === field,
  );
  const columns = tableItensColumns.slice(0, index);
  let offset = 0;
  columns.map((column: CustomGridColumns, _index) => {
    offset += column.width || 0;
    return column;
  });
  if (offset === 0) {
    document.getElementsByClassName(
      'tableEntradaNFeContainer',
    )[0].scrollLeft = 0;
    return;
  }
  const tableElement = document.querySelector<HTMLElement>('#tableEntradaNFe');
  const tableWidth = tableElement?.offsetWidth || 0;
  if (offset >= tableWidth) {
    document.getElementsByClassName('tableEntradaNFeContainer')[0].scrollLeft =
      tableWidth;
    return;
  }
  document.getElementsByClassName('tableEntradaNFeContainer')[0].scrollLeft =
    offset;
};

export const ScrollButtons: React.FC = () => {
  return (
    <>
      <ScrollButtonsContainer>
        <ScrollButton field="num_item">Identificador</ScrollButton>
        <ScrollButton field="val_peso">Quantidade</ScrollButton>
        <ScrollButton field="val_tot_desp_acess">Variáveis</ScrollButton>
        <ScrollButton field="tipo_tributacao">Tributação</ScrollButton>
        <ScrollButton field="val_tot_bc_icms">ICMS</ScrollButton>
        <ScrollButton field="val_tot_bc_st">ST</ScrollButton>
        <ScrollButton field="val_pauta_iva">Impostos</ScrollButton>
        <ScrollButton field="val_tot_final">Totais</ScrollButton>
      </ScrollButtonsContainer>
    </>
  );
};
