import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { formatCurrencyAsText } from '~/utils/functions';

export const impostosDiversosColumns: CustomGridColumns[] = [
  {
    field: 'val_pauta_iva',
    headerName: 'Pauta IVA',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_pauta_iva } = row;
      return <span>{formatCurrencyAsText(val_pauta_iva)}</span>;
    },
  },
  {
    field: 'val_pauta_ipv',
    headerName: 'Pauta IPV',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_pauta_ipv } = row;
      return <span>{formatCurrencyAsText(val_pauta_ipv)}</span>;
    },
  },
  {
    field: 'val_tot_st_fcp',
    headerName: 'FCP ST',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_st_fcp } = row;
      return <span>{formatCurrencyAsText(val_tot_st_fcp)}</span>;
    },
  },
  {
    field: 'val_tot_icms_fcp',
    headerName: 'FCP ICMS',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_icms_fcp } = row;
      return <span>{formatCurrencyAsText(val_tot_icms_fcp)}</span>;
    },
  },
];
