import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { formatCurrencyAsText } from '~/utils/functions';

export const icmsColumns: CustomGridColumns[] = [
  {
    field: 'val_tot_bc_icms',
    headerName: 'Tot. BC ICMS OP',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_bc_icms } = row;
      return <span>{formatCurrencyAsText(val_tot_bc_icms)}</span>;
    },
  },
  {
    field: 'val_tot_icms_op',
    headerName: 'ICMS OP',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_icms_op } = row;
      return <span>{formatCurrencyAsText(val_tot_icms_op)}</span>;
    },
  },
  {
    field: 'val_tot_isento',
    headerName: 'Tot. Isento',
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_isento } = row;
      return <span>{formatCurrencyAsText(val_tot_isento)}</span>;
    },
  },
  {
    field: 'val_tot_outros_icms',
    headerName: 'Tot. Outros',
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_outros_icms } = row;
      return <span>{formatCurrencyAsText(val_tot_outros_icms)}</span>;
    },
  },
];
