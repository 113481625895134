import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';

export const quantidadeColumns: CustomGridColumns[] = [
  {
    field: 'val_peso',
    headerName: 'Peso',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_peso } = row;
      return <span>{formatCurrencyAsText(val_peso)}</span>;
    },
  },
  {
    field: 'des_unidade',
    headerName: 'UN',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'qtd_embalagem',
    headerName: 'Qtd. Embalagem',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { qtd_embalagem } = row;
      return <span>{formatCurrencyAsText(qtd_embalagem)}</span>;
    },
  },
  {
    field: 'qtd_entrada',
    headerName: 'Qtd. Entrada',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { qtd_entrada } = row;
      return <span>{formatCurrencyAsText(qtd_entrada)}</span>;
    },
  },
  {
    field: 'qtd_total',
    headerName: 'Qtd. Total',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { qtd_entrada, qtd_embalagem, val_peso } = row;
      return (
        <span>
          {formatCurrencyAsText((qtd_entrada * qtd_embalagem) / val_peso)}
        </span>
      );
    },
  },
  {
    field: 'val_tabela',
    headerName: 'Valor Tabela',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tabela } = row;
      return <span>{formatCurrencyAsText(val_tabela, 2, 4)}</span>;
    },
  },
  {
    field: 'tot_tabela',
    headerName: 'Total Tabela',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { tot_tabela } = row;
      return <span>{formatCurrencyAsText(tot_tabela)}</span>;
    },
  },
];
