import React, { useCallback } from 'react';

import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

import { Checkbox } from '@atlaskit/checkbox';

import { tableItensColumns } from './TableItens';

import {
  TableContainer,
  Container,
  ButtonRow,
  ButtonRowContainer,
} from './styles';
import { ScrollButtons } from './ScrollButtons';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import { GoPencil } from 'react-icons/go';
import { FaTrash } from 'react-icons/fa';
import { Util } from '~/pages/EntradaNFE/functions/Util';
import { Produto } from '~/pages/EntradaNFE/protocols';
import {
  formatCurrencyAsText,
  moneyFormat,
  transformAsCurrency,
} from '~/utils/functions';
import Swal from 'sweetalert2';
import { deleteItens } from '~/utils/masterDetail';
import CustomDataGrid from '../CustomDataGrid';

export const TableItens: React.FC = () => {
  const {
    produtos,
    setProdutos,
    setProduto,
    masterDetail,
    setMasterDetail,
    loadingTable,
    produto,
    setProdutoModalNaoCalcBc,
    setShowModalNaoCalcBc,
    fornecedor,
    loja,
    isUpdate,
    canUpdate,
    setUuidItens,
    formItem,
    codXml,
  } = useEntradaNFE();

  const updateProdutos = useCallback(() => {
    const updatedProdutos = produtos.map((p) => {
      const cfopSegment = p.cfop.slice(1, 4);

      if (cfopSegment === '910' || cfopSegment === '920')
        return { ...p, flg_bonificado: true };

      return { ...p, flg_bonificado: false };
    });

    return updatedProdutos;
  }, [produtos]);

  const onFlgNaoCalcBcClick = (params: Produto) => {
    const findProduto = updateProdutos().find((reg) => {
      return reg.num_item === params.num_item;
    });

    if (findProduto) {
      const demaisProdutos = updateProdutos().filter((reg) => {
        return reg.num_item !== params.num_item;
      });
      findProduto.flg_nao_calc_bc = !findProduto.flg_nao_calc_bc;
      const updatedProducts = [...demaisProdutos, findProduto].sort(
        (a, b) => a.num_item - b.num_item,
      );
      setProdutos(updatedProducts);
      setProdutoModalNaoCalcBc(findProduto);
      setShowModalNaoCalcBc(true);
    }
  };

  const onEditItem = (params: any) => {
    setUuidItens({
      uuid: params.uuid,
      cod_seq_nf_item: params.cod_seq_nf_item,
    });
    formItem.setValue('des_referencia', params.des_referencia);

    formItem.setValue('des_unidade', {
      value: params.des_unidade.trim(),
      label: params.des_unidade.trim(),
    });
    formItem.setValue('cod_tributacao', {
      label: '',
      value: params.cod_tributacao,
    });

    formItem.setValue('cfop', {
      label: params.des_cfop,
      value: params.cfop.trim(),
    });

    if (params?.des_unidade.trim() === params?.des_unidade_compra.trim()) {
      formItem.setValue('tipo_embalagem', { label: 'Compra', value: 0 });
    } else {
      formItem.setValue('tipo_embalagem', { label: 'Venda', value: 1 });
    }

    formItem.setValue('qtd_embalagem', params.qtd_embalagem);
    formItem.setValue('qtd_entrada', params.qtd_entrada);
    formItem.setValue('flg_bonificado', params.flg_bonificado);
    formItem.setValue('val_tabela', moneyFormat(params.val_tabela, 4, 4));
    formItem.setValue(
      'val_tot_venda_varejo',
      formatCurrencyAsText(params.val_tot_venda_varejo),
    );
    formItem.setValue(
      'val_tot_desp_acess',
      formatCurrencyAsText(params.val_tot_desp_acess),
    );
    formItem.setValue(
      'val_tot_desconto',
      formatCurrencyAsText(params.val_tot_desconto),
    );
    formItem.setValue(
      'val_tot_acrescimo',
      formatCurrencyAsText(params.val_tot_acrescimo),
    );
    formItem.setValue(
      'val_tot_frete',
      formatCurrencyAsText(params.val_tot_frete),
    );
    formItem.setValue('val_tot_ipi', formatCurrencyAsText(params.val_tot_ipi));
    formItem.setValue('val_tot_st', formatCurrencyAsText(params.val_tot_st));

    const {
      cod_gtin,
      cod_gtin_principal,
      cod_id_ctb,
      cod_ncm,
      cod_produto,
      cod_tipo_item,
      cod_trib_entrada,
      cod_tributacao,
      des_produto,
      des_unidade_compra,
      des_unidade_venda,
      flg_ipv,
      flg_nao_pis_cofins,
      num_excecao_tipi,
      num_item,
      num_ncm,
      per_aliq_cofins,
      per_aliq_pis,
      per_desp_op,
      per_fcp_icms,
      per_fcp_st,
      per_icms_saida,
      per_pauta_iva,
      qtd_embalagem_compra,
      qtd_embalagem_venda,
      qtd_est_atual,
      tipo_nao_pis_cofins,
      tipo_trib_entrada,
      val_custo_medio_ant,
      val_custo_rep,
      val_custo_tabela,
      val_pauta_ipv,
      val_pauta_iva,
      val_peso,
    } = params;

    setProduto({
      ...produto,
      cod_gtin_principal,
      cod_id_ctb,
      cod_ncm,
      cod_produto,
      cod_tipo_item,
      cod_trib_entrada,
      cod_tributacao,
      des_produto,
      des_unidade_compra,
      des_unidade_venda,
      flg_ipv,
      flg_nao_pis_cofins,
      num_excecao_tipi,
      num_item,
      num_ncm,
      per_aliq_cofins,
      per_aliq_pis,
      per_desp_op,
      per_fcp_icms,
      per_fcp_st,
      per_icms_saida,
      per_pauta_iva,
      qtd_embalagem_compra,
      qtd_embalagem_venda,
      qtd_est_atual,
      tipo_nao_pis_cofins,
      tipo_trib_entrada,
      val_custo_medio_ant,
      val_custo_rep,
      val_custo_tabela,
      val_pauta_ipv,
      val_pauta_iva,
      val_peso,
    });
    formItem.setValue('cod_produto', {
      label: `${cod_produto} - ${des_produto}`,
      value: cod_produto,
    });
  };

  const actions: CustomGridColumns[] = [
    {
      field: 'acoes',
      headerName: 'Ações',
      fixed: true,
      fixedDirection: 'right',
      width: 250,
      renderCell: (params: any) => {
        return (
          <ButtonRowContainer>
            <ButtonRow type="button" title="Ignora Base de Cálculo?">
              <div className="igBc" style={{ cursor: 'pointer' }}>
                <Checkbox
                  label="Ig. BC?"
                  isDisabled={!canUpdate}
                  isChecked={params.flg_nao_calc_bc}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (canUpdate) {
                      onFlgNaoCalcBcClick(params);
                    }
                  }}
                />
              </div>
            </ButtonRow>
            <ButtonRow
              type="button"
              onClick={() => onEditItem(params)}
              disabled={!canUpdate || codXml !== undefined}
              title="Editar"
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} />
            </ButtonRow>
            <ButtonRow
              type="button"
              onClick={() => onRemoveItem(params)}
              disabled={!canUpdate || codXml !== undefined}
              title="Remover"
            >
              <FaTrash size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </ButtonRowContainer>
        );
      },
    },
  ];

  const columns: CustomGridColumns[] = [...tableItensColumns, ...actions];

  const onRemoveItem = (params: any) => {
    Swal.fire({
      title: ``,
      text: 'Deseja realmente excluir esse item?',

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { uuid } = params;

        const produtosData: any[] = await deleteItens(
          'nf_itens',
          uuid,
          masterDetail,
          setMasterDetail,
        );
        const produtosReordenados = Util.reordenarProdutos(produtosData);
        setProdutos(produtosReordenados);
        setUuidItens(undefined);
      }
    });
  };

  return (
    <Container>
      <ScrollButtons />
      <TableContainer>
        <CustomDataGrid
          loading={loadingTable}
          rows={updateProdutos()}
          columns={columns}
        />
      </TableContainer>
    </Container>
  );
};
