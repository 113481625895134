import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import { formatCurrencyAsText, roundByRule } from '~/utils/functions';

type Calculos = {
  valTotProdItem: number;
  valBcIcms: number;
  valTotCredIcmsItem: number;
  valTotFrete: number;
  valTotRetidoItem: number;
  valTotStXml: number;
  valTotFcpSt: number;
  valTotIva: number;
  valTotIpiItem: number;
  valTotIcmsAntec: number;
  valTotNfItem: number;
  valTotNfItemPed: number;
};

export const Resumo: React.FC = () => {
  const { produtos, setSubTotais } = useEntradaNFE();
  const [calculos, setCalculos] = useState<Calculos>({} as Calculos);

  useEffect(() => {
    calculaResumo();
  }, [produtos]);

  const calculaResumo = () => {
    let qtdTotItem = 0;
    let valTotStItem = 0;
    let valTotProdItem = 0;
    const valTotBonifItem = 0;
    let valTotRetidoItem = 0;
    let valTotStXml = 0;
    let valTotFcpSt = 0;
    let valTotGuiaItem = 0;
    const valTotGuiaItemDif = 0;
    let valTotIpiItem = 0;
    let valTotCredIcmsItem = 0;
    let valTotFrete = 0;
    let valTotNfItem = 0;
    const valTotNfItemPed = 0;
    let valBcIcms = 0;
    const valTotIcmsAntec = 0;

    produtos.forEach((produto) => {
      const qtdEmbalagem = produto.qtd_embalagem;
      if (qtdEmbalagem === 0) return;

      const qtdEntrada = produto.qtd_entrada;
      if (qtdEntrada === 0 || produto.val_tabela === 0) {
        return;
      }

      qtdTotItem += qtdEntrada * qtdEmbalagem;
      valTotStXml += produto.val_icms_st_xml;

      if (produto.flg_guia) {
        valTotGuiaItem += produto.val_tot_st_guia;
      } else {
        valTotStItem += produto.val_tot_produto;
        valTotRetidoItem += produto.val_tot_st;
        valTotFcpSt += produto.val_tot_st_fcp;
      }

      // valTotIcmsAntec += produto.val_icms_antec

      valBcIcms += produto.val_tot_bc_icms;
      valTotCredIcmsItem += produto.val_tot_icms_op;

      valTotIpiItem += produto.val_tot_ipi;
      valTotFrete += produto.val_tot_frete;
      valTotProdItem += produto.val_tot_produto;

      if (produto.val_tot_nf > 0) {
        valTotNfItem += produto.val_tot_nf;
      }
    });

    const totalNf = produtos.reduce(
      (acumulador, item) => acumulador + (item.val_tabela_final || 0),
      0,
    );

    valTotStItem = parseFloat(valTotStItem.toFixed(2));
    valTotRetidoItem = parseFloat(valTotRetidoItem.toFixed(2));
    valTotStXml = parseFloat(valTotStXml.toFixed(2));
    valTotFcpSt = parseFloat(valTotFcpSt.toFixed(2));
    valTotGuiaItem = parseFloat(valTotGuiaItem.toFixed(2));
    // valTotIcms
    valTotProdItem = parseFloat(valTotProdItem.toFixed(2));
    valBcIcms = parseFloat(valBcIcms.toFixed(2));
    valTotIpiItem = parseFloat(valTotIpiItem.toFixed(2));
    valTotCredIcmsItem = parseFloat(valTotCredIcmsItem.toFixed(2));
    valTotFrete = parseFloat(valTotFrete.toFixed(2));
    valTotNfItem = parseFloat(valTotNfItem.toFixed(2));

    // valTotNfItemPed = parseFloat(valTotNfItemPed.toFixed(2));

    valTotProdItem = roundByRule(valTotProdItem);
    valTotNfItem = roundByRule(valTotNfItem);

    const valTotIva = valTotGuiaItem + valTotGuiaItemDif;
    setSubTotais({
      valTotProdItem,
      valBcIcms,
      valTotCredIcmsItem,
      valTotFrete,
      valTotRetidoItem,
      valTotStXml,
      valTotFcpSt,
      valTotIva,
      valTotIpiItem,
      valTotIcmsAntec,
      valTotNfItem,
      valTotNfItemPed,
    });
    setCalculos({
      valTotProdItem,
      valBcIcms,
      valTotCredIcmsItem,
      valTotFrete,
      valTotRetidoItem,
      valTotStXml,
      valTotFcpSt,
      valTotIva,
      valTotIpiItem,
      valTotIcmsAntec,
      valTotNfItem,
      valTotNfItemPed,
    });

    // TODO - verificar esses blocos

    // if (Tot_GUIA_Item_Dif <= 0.10) and ((ImportNFe) or (BloqEdicNfe))  then
    // begin
    //   Tot_GUIA_Item_Dif := 0.00;
    //   BZeraValGuiaSTDif := True;
    //   ZeraValGuiaDifItens;
    //   BZeraValGuiaSTDif := False;
    // end
    // else
    // begin
    //   Tot_GUIA_Item_Dif := FltRound(Tot_GUIA_Item_Dif,2);
    //   BZeraValGuiaSTDif := False;
    // end;

    // if CliDStCodFiscSIMPLIFICADO.AsString = 'N' then
    // if (((CliDStMestreFLG_PRODUTOR_RURAL.AsString = 'S') or (CliDStMestreEXPORTADOR.AsString = 'S')) and (CliDStCodFiscTIPO_OPERACAO.AsInteger = 0)) then
    //   if (not CliSelect.Active) then
    //     if Val_Tot_NF_Item <> 0.00 then
    //     begin

    //       with QryAliqRet do
    //       begin
    //         close;
    //         CliAliqRet.close;
    //         ParamByName('TIPO_PRODUTOR').AsInteger := CliDStMestreTIPO_PRODUTOR.AsInteger;
    //         ParamByName('COD_ID_CTB').AsInteger    := MTabItensCOD_ID_CTB.AsInteger;
    //         CliAliqRet.Open;
    //       end;

    //       ValINSS := fltRound(CalculaICMS(Val_Tot_NF_Item, CliAliqRetPER_ALIQ.AsFloat, 0.00, 0, False, False), 2);
    //     end;
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={4}>
          <strong>Total dos Produtos:</strong>{' '}
          {formatCurrencyAsText(calculos.valTotProdItem)}
        </Col>
        <Col sm={12} md={4}>
          <strong>ST (NF):</strong>{' '}
          {formatCurrencyAsText(calculos.valTotRetidoItem)}
        </Col>
        <Col sm={12} md={4}>
          <strong>Frete (NF):</strong>{' '}
          {formatCurrencyAsText(calculos.valTotFrete)}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12} md={4}>
          <strong>BC ICMS OP:</strong>{' '}
          {formatCurrencyAsText(calculos.valBcIcms)}
        </Col>
        <Col sm={12} md={4}>
          <strong>ST (XML):</strong>{' '}
          {formatCurrencyAsText(calculos.valTotStXml)}
        </Col>
        <Col sm={12} md={4}>
          <strong>(Guia) ST:</strong> {formatCurrencyAsText(calculos.valTotIva)}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12} md={4}>
          <strong>ICMS OP:</strong>{' '}
          {formatCurrencyAsText(calculos.valTotCredIcmsItem)}
        </Col>
        <Col sm={12} md={4}>
          <strong>FCP ST:</strong> {formatCurrencyAsText(calculos.valTotFcpSt)}
        </Col>
        <Col sm={12} md={4}>
          <strong>Val. IPI:</strong>{' '}
          {formatCurrencyAsText(calculos.valTotIpiItem)}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12} md={4}>
          <strong>Total da NF:</strong>{' '}
          {formatCurrencyAsText(calculos.valTotNfItem)}
        </Col>
      </Row>
    </Container>
  );
};
