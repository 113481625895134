import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 0;
`;

export const TableContainer = styled.div`
  height: 100%;
  .MuiTableCell-stickyHeader {
    background-color: ${({ theme: { colors } }) => colors.nav};
  }
`;

export const ScrollButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 0.25rem;
  gap: 0.25rem;
  flex-wrap: wrap;

  button {
    background-color: #57069e;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-family: Jost, sans-serif;
    &:active,
    &:focus,
    &:hover {
      background-color: #8850bf;
      outline: none;
      box-shadow: none;
    }
  }
`;

export const ButtonRow = styled.button`
  background: none;
  border: none;
  color: #3d3d3d;
  height: 40px;
  min-width: 60px;
  svg {
    font-size: 16pt;
  }
  .igBc {
    width: 100px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    input {
      margin-top: -20px;
    }
  }
`;

export const ButtonRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.5s;
  button:disabled {
    cursor: not-allowed;
  }
`;
