import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { icmsColumns } from './Icms';

import { identificadorColumns } from './Idenficador';
import { impostosDiversosColumns } from './ImpostosDiversos';
import { quantidadeColumns } from './Quantidade';
import { stColumns } from './St';
import { totaisColumns } from './Totais';
import { tributacaoCadastroColumns } from './TributacaoCadastro';
// eslint-disable-next-line import/no-cycle
import { variaveisColumns } from './Variaveis';

export const tableItensColumns: CustomGridColumns[] = [
  ...identificadorColumns,
  ...quantidadeColumns,
  ...variaveisColumns,
  ...tributacaoCadastroColumns,
  ...icmsColumns,
  ...stColumns,
  ...impostosDiversosColumns,
  ...totaisColumns,
];
