import React from 'react';
import { Capa } from './Capa';
import { Itens } from './Itens';
import { Resumo } from './Resumo';

export const Nfe: React.FC = () => {
  return (
    <>
      <Capa />
      <Itens />
      <Resumo />
    </>
  );
};
