import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { formatCurrencyAsText } from '~/utils/functions';

export const stColumns: CustomGridColumns[] = [
  {
    field: 'val_tot_bc_st',
    headerName: 'Tot. BC ST (NF)',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_bc_st } = row;
      return <span>{formatCurrencyAsText(val_tot_bc_st)}</span>;
    },
  },
  {
    field: 'val_bc_st_xml',
    headerName: 'Tot. BC ST (XML)',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_bc_st_xml } = row;
      return <span>{formatCurrencyAsText(val_bc_st_xml)}</span>;
    },
  },
  {
    field: 'val_tot_bc_st_guia_s',
    headerName: 'Tot. BC ST (Guia)',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_bc_st_guia_s } = row;
      return <span>{formatCurrencyAsText(val_tot_bc_st_guia_s)}</span>;
    },
  },
  {
    field: 'val_tot_bc_st_guia_dif',
    headerName: 'Tot. BC ST (Guia DIF)',
    width: 170,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_bc_st_guia_dif } = row;
      return <span>{formatCurrencyAsText(val_tot_bc_st_guia_dif)}</span>;
    },
  },
  {
    field: 'val_tot_st',
    headerName: 'Tot. ST (NF)',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_st } = row;
      return <span>{formatCurrencyAsText(val_tot_st)}</span>;
    },
  },
  {
    field: 'val_icms_st_xml',
    headerName: 'Tot. ST (XML)',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_icms_st_xml } = row;
      return <span>{formatCurrencyAsText(val_icms_st_xml)}</span>;
    },
  },
  {
    field: 'val_tot_st_guia',
    headerName: 'Tot. ST (Guia)',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_st_guia } = row;
      return <span>{formatCurrencyAsText(val_tot_st_guia)}</span>;
    },
  },
  {
    field: 'val_tot_st_guia_dif',
    headerName: 'Tot. ST (Guia DIF)',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_st_guia_dif } = row;
      return <span>{formatCurrencyAsText(val_tot_st_guia_dif)}</span>;
    },
  },
];
